import React, { useState } from "react"
import GitexPopupModal from "../../../core/GitexPopupModal"
const CESSectionNew = () => {
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/lets-connect-at-ces-2025"}
        />
      )}
      <div className={`px-4 sm:px-6 w-full lg:px-12 bg-white`}>
        <div className="w-full flex lg:flex-row flex-col justify-center items-center min-h-screen py-8 md:py-16 lg:py-20 xl:py-20">
          <div className="lg:w-6/12 md:px-12 px-6 md:mt-24 lg:my-0  ">
            <div className="bg-white rounded-md">
              <div className="relative mx-auto rounded-md block">
                <div className="relative flex justify-center rounded-md">
                  <div className="relative flex items-center mx-auto ">
                    <img
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/CES-calendar.webp"
                      alt="CES 2025"
                      // title="CES 2025"
                      className="h-full"
                      height="100%"
                      width="100%"
                      loading="lazy"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12 w-full flex justify-center items-center lg:px-3 px-4">
            <div className="flex justify-center flex-col ">
              {/* <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-12">
                <img
                  // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Logo-london-tech-week.png"
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/09/Gitex2024-Logo.webp"
                  alt="WebMobTech in Dubai"
                  title="WebMobTech in Dubai"
                  height="110px"
                  width="440px"
                  className="h-full w-auto"
                  loading="lazy"
                />
              </div> */}
              <div className="flex lg:justify-start justify-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: `14 Years of Tech Excellence`,
                  }}
                  className="font-serif font-bold lg:text-5xl text-3xl tracking-normal pb-8 text-shark-500 lg:text-left text-center"
                />
              </div>

              {/* <div className="flex lg:justify-start justify-center">
                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 px-2 rounded lg:text-left text-center mb-2">
                We are a trusted technology partner by businesses from 25+ countries with 14+ years. of exp. & a team of 120+ inhouse developers who are well-versed with 25+ latest technologies and delivered 500+ projects successfully.
                </p>
              </div> */}

              <div className="flex lg:justify-start justify-center">
                <p className="font-medium lg:text-xl md:text-xl text-lg font-sans text-shark-500 px-2 rounded lg:text-left text-center">
                  Since 2010, WebMobTech has delivered 500+ projects across 25
                  industries globally. With a team of 120+ skilled professionals
                  in 25+ technologies, we drive business innovation with
                  future-ready software and AI-powered solutions. Let’s create
                  impactful ideas together.
                </p>
              </div>
              <div className="flex lg:justify-start justify-center">
                <p className="font-medium lg:text-xl md:text-xl text-lg font-sans mt-6 text-shark-500 px-2 rounded lg:text-left text-center flex items-center">
                  <span className="mr-2">
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0.0419922C9.35398 0.0449033 6.81712 1.09727 4.94598 2.96822C3.07484 4.83918 2.02222 7.37593 2.01904 10.022C2.01904 12.592 4.00904 16.614 7.93404 21.976C8.4013 22.6161 9.0131 23.1369 9.71964 23.4959C10.4262 23.8549 11.2075 24.042 12 24.042C12.7926 24.042 13.5739 23.8549 14.2804 23.4959C14.987 23.1369 15.5988 22.6161 16.066 21.976C19.991 16.614 21.981 12.592 21.981 10.022C21.9779 7.37593 20.9252 4.83918 19.0541 2.96822C17.183 1.09727 14.6461 0.0449033 12 0.0419922ZM12 14C11.2089 14 10.4356 13.7654 9.77776 13.3259C9.11997 12.8863 8.60728 12.2616 8.30452 11.5307C8.00177 10.7998 7.92256 9.99556 8.0769 9.21963C8.23124 8.44371 8.61221 7.73098 9.17162 7.17157C9.73103 6.61216 10.4438 6.23119 11.2197 6.07685C11.9956 5.92251 12.7999 6.00172 13.5308 6.30448C14.2617 6.60723 14.8864 7.11992 15.3259 7.77771C15.7654 8.43551 16 9.20887 16 9.99999C16 11.0609 15.5786 12.0783 14.8285 12.8284C14.0783 13.5786 13.0609 14 12 14Z" />
                    </svg>
                  </span>
                  <span className="font-medium lg:text-xl md:text-xl text-lg font-sans text-shark-500 md:px-2  rounded  lg:text-left text-center">
                    Booth 9542, North Hall, LVCC
                  </span>
                </p>
              </div>
              <div className="flex lg:justify-start justify-center items-center">
                <p className="font-medium lg:text-xl md:text-xl text-lg font-sans mt-6 text-shark-500 px-2 rounded lg:text-left text-center flex items-center">
                  <span className="mr-2">
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 2H18V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0C16.7348 0 16.4804 0.105357 16.2929 0.292893C16.1054 0.48043 16 0.734784 16 1V2H8V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0C6.73478 0 6.48043 0.105357 6.29289 0.292893C6.10536 0.48043 6 0.734784 6 1V2H5C3.67441 2.00159 2.40356 2.52888 1.46622 3.46622C0.528882 4.40356 0.00158786 5.67441 0 7L0 8H24V7C23.9984 5.67441 23.4711 4.40356 22.5338 3.46622C21.5964 2.52888 20.3256 2.00159 19 2Z" />
                      <path d="M0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V10H0V19ZM17 14.5C17.2967 14.5 17.5867 14.588 17.8334 14.7528C18.08 14.9176 18.2723 15.1519 18.3858 15.426C18.4993 15.7001 18.5291 16.0017 18.4712 16.2926C18.4133 16.5836 18.2704 16.8509 18.0607 17.0607C17.8509 17.2704 17.5836 17.4133 17.2926 17.4712C17.0017 17.5291 16.7001 17.4994 16.426 17.3858C16.1519 17.2723 15.9176 17.08 15.7528 16.8334C15.588 16.5867 15.5 16.2967 15.5 16C15.5 15.6022 15.658 15.2206 15.9393 14.9393C16.2206 14.658 16.6022 14.5 17 14.5ZM12 14.5C12.2967 14.5 12.5867 14.588 12.8334 14.7528C13.08 14.9176 13.2723 15.1519 13.3858 15.426C13.4994 15.7001 13.5291 16.0017 13.4712 16.2926C13.4133 16.5836 13.2704 16.8509 13.0607 17.0607C12.8509 17.2704 12.5836 17.4133 12.2926 17.4712C12.0017 17.5291 11.7001 17.4994 11.426 17.3858C11.1519 17.2723 10.9176 17.08 10.7528 16.8334C10.588 16.5867 10.5 16.2967 10.5 16C10.5 15.6022 10.658 15.2206 10.9393 14.9393C11.2206 14.658 11.6022 14.5 12 14.5ZM7 14.5C7.29667 14.5 7.58668 14.588 7.83336 14.7528C8.08003 14.9176 8.27229 15.1519 8.38582 15.426C8.49935 15.7001 8.52906 16.0017 8.47118 16.2926C8.4133 16.5836 8.27044 16.8509 8.06066 17.0607C7.85088 17.2704 7.58361 17.4133 7.29264 17.4712C7.00166 17.5291 6.70006 17.4994 6.42597 17.3858C6.15189 17.2723 5.91762 17.08 5.7528 16.8334C5.58797 16.5867 5.5 16.2967 5.5 16C5.5 15.6022 5.65804 15.2206 5.93934 14.9393C6.22064 14.658 6.60218 14.5 7 14.5Z" />
                    </svg>
                  </span>
                  <span className="font-medium lg:text-xl md:text-xl text-lg font-sans text-shark-500  md:px-2 rounded lg:text-left text-center">
                    7 to 10 January 2025
                  </span>
                </p>
              </div>

              <div className="flex justify-center lg:justify-start w-full mt-3">
                <button
                  className="mt-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                  onClick={openGitexModal}
                >
                  Book a Meeting
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CESSectionNew
